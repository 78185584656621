@import '../../styles/color.scss';

.appHeader {
    .MuiToolbar-root {
        min-height: 47px;
    }

    .logoWrapper {
        a {
            color: $white;
            font-size: 1.25rem;
        }

        img {
            display: inline-block;
            width: 45px;
            position: relative;
            top: 4px;
        }
    }

    .userNameLabel {
        font-size: 0.875rem;
        padding-left: 5px;
    }

    .MuiIconButton-root {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .menuLinksWrapper {
        display: inline-flex;
        border-right: 1px solid $white;
        padding-right: 15px;

        a {
            line-height: 1;
            position: relative;
        }

        svg {
            color: $white;
        }
    }

    .nikeLink.active {
        &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            width: 40px;
            height: 2px;
            background-color: $blue;
            left: 50%;
            transform: translateX(-50%);
        }

        svg {
            color: $blue;
        }
    }

    @media screen and (max-width: 480px) {
        .userNameLabel {
            font-size: 0.688rem;
        }

        .logoWrapper {
            a {
                font-size: 0.875rem;
            }

            img {
                width: 32px;
            }
        }

        .menuLinksWrapper {
            padding-right: 5px;

            .MuiIconButton-root {
                padding: 8px;
            }
        }
    }
}
@import '../../styles/color.scss';

.nikeLink {
    color: $blue;
    text-transform: initial;
    font-size: 0.938rem;

    @media screen and (max-width: 480px) {
        font-size: 0.688rem;
    }
}
@import url('https://www.nike.com/assets/ncss/3.0/dotcom/desktop/css/ncss.en-us.min.css');
@import './color.scss';

/** HelveticaNeue font-family */
@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('../constants/fonts/HelveticaNeue/HelveticaNeue-Light-08.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('../constants/fonts/HelveticaNeue/HelveticaNeue-01.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('../constants/fonts/HelveticaNeue/HelveticaNeue-Medium-11.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('../constants/fonts/HelveticaNeue/HelveticaNeue-Bold-02.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'HelveticaNeue', 'Ubuntu', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiTypography-h5,
.MuiInputBase-input,
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  font-family: 'HelveticaNeue', 'Ubuntu', 'Helvetica Neue',
    sans-serif !important;
}

.mainWrapper {
  min-height: calc(100vh - 78px);
  padding: 12px 25px;
  background-color: $lightBG;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Arial, 'HelveticaNeue', sans-serif;
}

.MuiTypography-root {
  &.pageTitle {
    font-size: 1.5rem;
  }
}

.sessionTimeoutDialog {
  .title {
    margin-bottom: 10px;
  }

  .MuiPaper-root {
    max-width: 375px;
  }

  .MuiDialogActions-root {
    margin-top: 10px;
  }
}

.headline-3 {
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .mainWrapper {
    padding: 20px 15px;
  }

  .headline-3 {
    font-size: 1rem;
  }

  .MuiFormControl-root {
    width: 100%;
  }
}
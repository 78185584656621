/**
 File contains color variables and classes
 Files can be imported directly in styles.scss wherever required.
*/

$black: #000000;
$primaryblack: #111111;
$white: #ffffff;
$offWhite: #efefef;
$spinnerGrey: #8c8c8c;
$darkBG: #3a3a3a;
$lightBG: #f5f5f5;
$borderColor: #d3d3d3;
$textColorDark:#515151;
$textColor: #6f6f6f;
$TextColorTwo: #757575;
$blueText: #3585DB;
$blue: #007BFF;
$AlertColor: #D43F21;
$green: #128A09;
$pieRed: #D43F21;
$pieGreen: #449331;
$pieBlue: #54A1E5;
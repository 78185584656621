@import '../../styles/color.scss';

.nikeFooter {
  border-top: 1px solid $borderColor;
  height: 30px;
  align-items: center;
  display: flex;
  padding: 5px 25px;
  
  p {
    font-size: 0.75rem;
    color: $textColor;
    text-align: left;
  }
}
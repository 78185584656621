@import '../../styles/color.scss';

.notificationDialog .MuiDialogActions-root {
  justify-content: center;
  margin-top: 21px;
  margin-bottom: 32px;
}

.notificationDialog .MuiPaper-root {
  max-width: 585px;
  border-radius: 20px;
  padding: 10px
}

.notificationDialog .imageContainer img {
  margin: auto;
}

.notificationDialog {
  text-align: center;
}

.notificationDialog .textContainer .title {
  font-size: 2rem;
  font-weight: bold;
}

.notificationDialog .textContainer .description {
  font-size: 1.25rem;
  color: $primaryblack;
}

div .btn-nike-rounded-black.nikeButton{
  margin-right: 5px;
}

@media screen and (max-width: 480px) {
  .notificationDialog .textContainer .description {
    font-size: 1rem;
    word-break: break-word;
  }
}

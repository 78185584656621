@import '../../styles/color.scss';

.errorBoundary {
    max-width: 400px;
    margin: auto;
    text-align: center;
    
    img {
        margin-left: auto;
        margin-right: auto;
    }
    .error {
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 2.75rem;
    }
}
